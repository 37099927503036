import { animate, state, style, transition, trigger } from '@angular/animations';
import { CdkPortal, TemplatePortal } from '@angular/cdk/portal';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';

import { BrandService } from 'src/app/modules/core/services/brand.service';
import { SettingsService } from 'src/app/modules/core/services/settings.service';
import { AppFacade } from 'src/app/modules/ngrx-store/app/app.facade';

import { BrandMapItem } from 'src/app/modules/shared/types/brand';
import { keyPressHandler } from 'src/app/modules/shared/utilities/accessibility.utils';
import { UnsubscribeOnDestroy } from 'src/app/modules/shared/utilities/unsubscribe-on-destroy';

@Component({
	selector: 'cp-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	animations: [
		trigger('hideShow', [
			state('hidden', style({
				opacity: 0,
			})),
			state('visible', style({
				opacity: 1,
			})),
			transition('hidden <=> visible', animate('0.15s ease-in-out')),
		])
	],
	standalone: false
})

export class HeaderComponent extends UnsubscribeOnDestroy implements OnInit {
	@Output() hamburgerClicked = new EventEmitter<MouseEvent>();

	@Input() isMyAccountEnabled: boolean = true;

	@ViewChild(CdkPortal) portal!: CdkPortal; // Portal that hosts the template content

	@HostBinding('@hideShow') animationState = true;

	overlayRef!: OverlayRef;

	userInfo$ = this.appFacade.userInfo$;

	brand?: BrandMapItem;

	logoSrc?: string;

	myAccountUrl: string | undefined;
	
	myAccountToggle = false;

	keyPressHandler = keyPressHandler;

	constructor(
		private brandService: BrandService,
		private appFacade: AppFacade,
		private settingsService: SettingsService,
		private overlay: Overlay,
		private viewContainerRef: ViewContainerRef,
		private elRef: ElementRef
	) {
		super();

		this.settingsService.getSettings().pipe(
			this.takeUntilDestroyed()
		).subscribe(settings => {
			const brand = this.brandService.getBrand();

			this.myAccountUrl = settings.brandConfigs[brand.name].myAccountUrl;
		});
	}

	ngOnInit(): void {
		this.brand = this.brandService.getBrand();
		this.logoSrc = this.brandService.getBrandImage();
	}

	//This method was created to toggle our custom cdk overlay; due to having to switch from using the mat-menu due to accessibility issues.
	openOverlay() {
		// If an overlay is already open, close it first to avoid duplication
		if (this.overlayRef && this.overlayRef.hasAttached()) {
			this.closeOverlay();
		}

		// Finds the container the overlay will be injected to so it won't be injected into the bottom of the body of the DOM
		const customContainer = this.elRef.nativeElement.querySelector('#cp-custom-container-aria');

		// Create the overlay and disable the default host element attachment to the body
		this.overlayRef = this.overlay.create({
			hasBackdrop: true, // Optionally add a backdrop
			scrollStrategy: this.overlay.scrollStrategies.reposition(),
			panelClass: 'cp-my-account-menu-overlay',
		});

		// Manually append the overlay to the custom container (by changing the parent node)
		if (this.overlayRef.hostElement && customContainer) {
			customContainer.appendChild(this.overlayRef.hostElement); // Append to custom container
		}

		// Attach the portal to the overlay
		const templatePortal = new TemplatePortal(this.portal.templateRef, this.viewContainerRef);
		this.overlayRef.attach(templatePortal);

		this.myAccountToggle = true;

		// Handle backdrop click to close overlay
		this.overlayRef.backdropClick().subscribe(() => this.closeOverlay());
	}

	closeOverlay() {
		// Remove the overlay from the DOM to force the animation to reset
		if (this.overlayRef) {
			this.myAccountToggle = false;

			// Gives the animation time to finish before disposing of the overlay
			setTimeout(() => {
				this.overlayRef.dispose();
			}, 150)
		}
	}
}